import { refreshThumbnail, downloadFile } from '../api/media';
import { pinPost, unpinPost } from '../api/space';
import { useState } from 'react';
import useDialogState from './useDialogState';
import { ADMIN_CP_URL } from './config';
import { useActions } from './useActions';
// import { NotificationAction } from '../state/notification';

import * as Icon from 'react-feather';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import RemoveFromSpaceModal from './RemoveFromSpaceModal';
import RenameMediaModal from './RenameMediaModal';

function PostContextMenu() {
  const [context, setContext] = useState({});

  // const showNotification = useActions(NotificationAction.show);

  // handle download
  const handleDownload = async () => {
    if (context.file) {
      await downloadFile(context.file);
    }
  };

  // handle remove files
  const [showRemoveFilesDialog, openRemoveFilesDialog, closeRemoveFilesDialog] =
    useDialogState(false);

  // handle rename
  const [showRenameDialog, openRenameDialog, closeRenameDialog] =
    useDialogState(false);

  // handle right click
  const handleContextMenuOpen = (e) => {
    const file = e.detail.data.file;
    const post = e.detail.data.post;
    const spaceId = e.detail.data.spaceId;
    const editable = e.detail.data.editable;
    const pinned = e.detail.data.pinned;

    setContext({ file, spaceId, post, editable, pinned });
  };

  const { file, spaceId, post, editable, pinned } = context;

  const handleMenuClick = (e, data) => {
    switch (data.action) {
      case 'open':
        window.open(`${ADMIN_CP_URL}/media/${file._id}/viewer`);
        break;
      case 'remove':
        openRemoveFilesDialog();
        break;
      case 'rename':
        openRenameDialog();
        break;
      case 'refresh_thumbnail':
        try {
          refreshThumbnail([file]);
        } catch (ex) {
          // log
          console.error(ex);
        }
        break;
      case 'pin':
        try {
          pinPost(spaceId, post?._id);
        } catch (error) {
          // log
        }
        break;
      case 'unpin':
        try {
          unpinPost(spaceId, post?._id);
        } catch (error) {
          // log
        }
        break;

      default:
        break;
    }
  };

  const isIntegration = file && file.type === 'integration';
  const isFile = file && file.type === 'file';
  const isApprovalRequired = post?.approvalRequired;

  return (
    <div>
      {showRemoveFilesDialog && (
        <RemoveFromSpaceModal
          spaceId={spaceId}
          posts={[post]}
          closeModal={closeRemoveFilesDialog}
        />
      )}

      {showRenameDialog && (
        <RenameMediaModal media={file} closeModal={closeRenameDialog} />
      )}

      <ContextMenu id="post_context_menu" onShow={handleContextMenuOpen}>
        <MenuItem data={{ action: 'open' }} onClick={handleMenuClick}>
          <Icon.ExternalLink /> Open Permalink
        </MenuItem>
        <MenuItem divider />
        {pinned ? (
          <MenuItem
            data={{ action: 'unpin' }}
            onClick={handleMenuClick}
            disabled={!editable}
          >
            <Icon.MousePointer /> Unpin this file
          </MenuItem>
        ) : (
          <MenuItem
            data={{ action: 'pin' }}
            onClick={handleMenuClick}
            disabled={!editable}
          >
            <Icon.MousePointer /> Pin this file
          </MenuItem>
        )}
        <MenuItem
          data={{ action: 'rename' }}
          onClick={handleMenuClick}
          disabled={isIntegration || !editable}
        >
          <Icon.Edit3 /> Rename file...
        </MenuItem>
        <MenuItem
          data={{ action: 'refresh_thumbnail' }}
          onClick={handleMenuClick}
          disabled={isIntegration || !editable}
        >
          <Icon.Repeat /> Refresh thumbnail
        </MenuItem>
        <MenuItem
          data={{ action: 'download' }}
          onClick={handleDownload}
          disabled={isIntegration}
        >
          <Icon.Download /> Download
        </MenuItem>
        <MenuItem divider />
        <MenuItem
          data={{ action: 'remove' }}
          onClick={handleMenuClick}
          disabled={!editable}
        >
          <Icon.Trash /> Remove...
        </MenuItem>
      </ContextMenu>
    </div>
  );
}

export default PostContextMenu;
