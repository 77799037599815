import { useState } from 'react';
import * as Icon from 'react-feather';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import get from 'lodash/get';
import path from 'lodash/fp/path';
import chunk from 'lodash/chunk';
import { updateBackdropTemplate } from '../api/backdrop';

import { useSelector } from 'react-redux';
import { AuthUserSelector } from '../state/authUser';
import { SpaceViewSelector } from '../state/spaceView';
import { WizardAction } from '../state/wizard';
import { NotificationAction } from '../state/notification';
import { useActions } from './useActions';
import { usePluginHost } from './usePluginHost';

import useDialogState from './useDialogState';
import useBackdropUppy from './useBackdropUppy';
import PostItem from './PostItem';
import UploadModal from './UploadModal';
import SaveDefaultTemplateButton from './SaveDefaultTemplateButton';
import SpaceBackgroundUploader from './SpaceBackgroundUploader';

function getPostRows(filteredPosts = [], pinnedPostId = null) {
  if (!pinnedPostId) {
    return chunk(filteredPosts, 2);
  }

  const pinnedPost = filteredPosts.find((post) => post._id === pinnedPostId);
  if (!pinnedPost) {
    return chunk(filteredPosts, 2);
  }

  const normalPosts = filteredPosts.filter((post) => post._id !== pinnedPostId);
  const reorderedPosts = [pinnedPost, ...normalPosts];
  return chunk(reorderedPosts, 2);
}

function BackdropContent() {
  const backdrop = useSelector(SpaceViewSelector.getSelectedBackdrop);

  // handle continue
  const currentMode = useSelector(SpaceViewSelector.getCurrentMode);
  const goToStepPermission = useActions(WizardAction.goToStepPermission);

  const spaceId = backdrop.space?._id;
  const pinnedPostId = backdrop.space?.pinnedPostId;
  const posts = backdrop.space?.posts || [];
  const filteredPosts = posts.filter((post) => !get(post, 'media[0].removed'));
  const mediaIds = filteredPosts.map(path('media[0]._id'));

  const rows = getPostRows(filteredPosts, pinnedPostId);
  const hasContent = filteredPosts.length > 0;

  const [showUploadModal, openUploadModal, closeUploadModal] =
    useDialogState(false);

  const uppy = useBackdropUppy({
    spaceId,
    inviteCode: backdrop.code,
    closeModal: closeUploadModal,
  });

  const handleOpenUploadModal = () => {
    uppy.reset();
    openUploadModal();
  };

  const showNotification = useActions(NotificationAction.show);
  const templates = useSelector(SpaceViewSelector.getTemplates);
  const handleTemplateChange = async (e) => {
    try {
      await updateBackdropTemplate(backdrop.code, e.target.value);
    } catch (error) {
      showNotification({
        title: 'Error',
        body: 'An error occurred. Please try again.',
      });
      console.log(error);
    }
  };
  const activeTemplateId = backdrop.template?._id ? backdrop.template._id : '';

  // insert and close
  const { insertUrlInMailBody, closeDrawer } = usePluginHost();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const viewerUrl = useSelector(AuthUserSelector.getViewerUrl);
  const url = `${viewerUrl}/${backdrop?.code}`;
  const handleInsertAndClose = async () => {
    setProcessing(true);
    setError('');

    try {
      // 1. insert into email body
      await insertUrlInMailBody(backdrop.code, backdrop.space.title, url);

      // 2. close
      await closeDrawer();
    } catch (ex) {
      console.error(ex);
      setError(ex.message);
    } finally {
      setProcessing(false);
    }
  };

  const org = useSelector(AuthUserSelector.getOrganization);
  const showTemplateSelect = Boolean(org?.features?.plugin_can_select_template);

  return (
    <div>
      {showTemplateSelect && (
        <div className="pd-b-15 pd-x-15">
          <InputGroup>
            <select
              className="custom-select"
              value={activeTemplateId}
              onChange={handleTemplateChange}
            >
              <option value="">No Template</option>
              {templates.map((template) => (
                <option key={template._id} value={template._id}>
                  {template.name}
                </option>
              ))}
            </select>
            <InputGroup.Append>
              <SaveDefaultTemplateButton templateId={activeTemplateId} />
            </InputGroup.Append>
          </InputGroup>
        </div>
      )}

      <div className="pd-x-15 mg-b-20">
        <Alert variant="info" className="mg-0">
          <span className="tx-13">
            {mediaIds.length} attachments shared.&nbsp;
            <a href="#" onClick={handleOpenUploadModal}>
              Add more...
            </a>
          </span>
        </Alert>
      </div>

      <div className="pd-x-8 pos-relative">
        <SpaceBackgroundUploader
          uppy={uppy}
          onDrop={handleOpenUploadModal}
          hasTemplateSelect={showTemplateSelect}
        >
          <div className="pd-x-6">
            {rows.map((row, index) => (
              <div className="row row-xs justify-content-start" key={index}>
                {row.map((post) => (
                  <div className="col-6" key={post._id} style={{ padding: 8 }}>
                    <PostItem
                      spaceId={spaceId}
                      post={post}
                      editable
                      pinned={pinnedPostId === post._id}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </SpaceBackgroundUploader>
      </div>

      <div className="pos-fixed l-0 r-0 b-0 bg-white">
        <div className="pd-x-15 pd-b-30">
          {currentMode !== 'reply' ? (
            <Button
              className="mg-t-30"
              variant="brand-02"
              block
              onClick={goToStepPermission}
            >
              <span className="pd-r-5">Continue</span>
              <Icon.ArrowRight />
            </Button>
          ) : (
            <Button
              className="mg-t-30"
              variant="brand-02"
              block
              disabled={processing}
              onClick={handleInsertAndClose}
            >
              {processing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Insert Link and Close</span>
              )}
            </Button>
          )}
        </div>
      </div>

      {showUploadModal && (
        <UploadModal closeModal={closeUploadModal} uppy={uppy} />
      )}
    </div>
  );
}

export default BackdropContent;
