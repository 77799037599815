import prettyBytes from 'filesize.js';
import { ContextMenuTrigger } from 'react-contextmenu';

import * as Icon from 'react-feather';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'react-bootstrap/Badge';

import { getFileClassnames, getIntegrationClassnames } from './helpers';
// import useDialogState from './useDialogState';
// import DocumentPreviewModal from './DocumentPreviewModal';

function PostItem(props) {
  const { spaceId, post, editable, pinned } = props;

  const file = post.media[0] || {};
  const { fileColorClassName, fileIconClassName } = getFileClassnames(file);

  // preview dialog
  // const [showPreview, openPreviewDialog, closePreviewDialog] =
  //   useDialogState(false);

  // media type
  const mediaType = file.type;
  const { iconClassName, color } = getIntegrationClassnames(
    file.integrationType
  );

  // annotation
  const hasAnnotation = post.annotation && post.annotation.length;

  // link provider
  const providerText = file.embedProviderName || file.embedProviderUrl;

  // has approval request?
  const isApprovalRequired = post?.approvalRequired;
  const isApproved = post?.isApproved;

  // is uploaded by recipient?
  const isUploadedByRecipient = Boolean(file.onBehalfOf);

  return (
    <ContextMenuTrigger
      id="post_context_menu"
      collect={() => ({ file, spaceId, post, editable, pinned })}
      holdToDisplay={-1}
    >
      <div
        id={`post_${post._id}`}
        className="card card-file document-item"
        // onDoubleClick={openPreviewDialog}
      >
        {/* {showPreview && (
          <DocumentPreviewModal
            initialFiles={[file]}
            closeModal={closePreviewDialog}
          />
        )} */}
        {file.thumbnailUrl ? (
          <div
            className="card-file-thumb"
            style={{
              backgroundImage: `url(${file.thumbnailUrl})`,
              backgroundSize: 'cover',
            }}
          ></div>
        ) : (
          <div className={`card-file-thumb ${fileColorClassName}`}>
            <i className={`far ${fileIconClassName}`} />
          </div>
        )}

        <div className="card-body">
          <h6>
            <a className="link-02 tx-12" title={file.title}>
              {file.title}
            </a>
          </h6>
          {mediaType === 'file' && (
            <span className="tx-10 tx-gray-600">
              {prettyBytes(file.fileSize || 0)}
            </span>
          )}
          {mediaType === 'integration' && (
            <div>
              <i
                className={`${iconClassName} tx-16`}
                style={{ color: color, verticalAlign: 'middle' }}
              ></i>{' '}
              <span className="tx-10 tx-gray-600">{file.integrationType}</span>
            </div>
          )}
          {mediaType === 'url' && (
            <div>
              <Icon.Globe size={13} color="#8392a5" />
              <span className="mg-l-5 tx-10 tx-gray-600">{providerText}</span>
            </div>
          )}
        </div>

        {pinned && (
          <div className="marker-icon marker-warning pos-absolute t--1 l--1">
            <Icon.Star />
          </div>
        )}

        <style jsx>{`
          .annotation-marker {
            cursor: pointer;
          }
        `}</style>
      </div>
    </ContextMenuTrigger>
  );
}

export default PostItem;
