function LibraryBackgroundView(props) {
  const { children } = props;

  return (
    <div className="background-view">
      {children}
      <style jsx>{`
        .background-view {
          background-color: #f8f9fc;
          position: absolute;
          top: 55px;
          bottom: 0;
          width: 100%;
          border: 2px solid transparent;
          transition: all 0.2s ease-in-out;
        }
      `}</style>
    </div>
  );
}

export default LibraryBackgroundView;
