import * as React from 'react';
import { DashboardModal } from '@uppy/react';

const uploadNote = `Supported file types: pdf, docx, pptx, xlsx, jpg, png, ps, ai.
If you need help uploading another file type, use our live support feature`;

function UploadModal(props) {
  const { uppy, closeModal } = props;

  return (
    <DashboardModal
      uppy={uppy}
      open
      onRequestClose={closeModal}
      showLinkToFileUploadResult={false}
      proudlyDisplayPoweredByUppy={false}
      note={uploadNote}
    />
  );
}

export default UploadModal;
