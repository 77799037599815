import * as React from 'react';
import { useSelector } from 'react-redux';

import * as Icon from 'react-feather';

import cx from 'classnames';
import { LibraryViewSelector, LibraryViewAction } from '../state/libraryView';
import Router from 'next/router';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useActions } from './useActions';

function ParentFolderItem(props) {
  const { folder } = props;

  const viewMode = useSelector(LibraryViewSelector.getViewMode);
  const goToFolder = useActions(LibraryViewAction.goToFolder);
  const goToPersonalFolder = useActions(LibraryViewAction.goToPersonalFolder);

  // handle open folder
  const handleOpenFolder = (e) => {
    e.preventDefault();

    if (viewMode === 'mine') {
      goToPersonalFolder(folder);
    } else {
      goToFolder(folder);
    }
  };

  // pass info to context menu
  const passInfo = () => ({
    folder,
  });

  return (
    <ContextMenuTrigger
      id="folder_context_menu"
      collect={passInfo}
      holdToDisplay={-1}
    >
      <div>
        <div
          className={cx('media media-folder')}
          onDoubleClick={handleOpenFolder}
          style={{ cursor: 'default', 'user-select': 'none' }}
        >
          <Icon.ArrowUp size={18} />

          <div className="media-body">
            <h6>
              <a className="link-02" style={{ color: 'inherit' }}>
                Go up
              </a>
            </h6>
          </div>
        </div>
      </div>
    </ContextMenuTrigger>
  );
}

export default ParentFolderItem;
