import api from './feathers';

export const isSuperAdmin = (user) =>
  user.email === 'long@podzim.co' || user.email === 'adam@backdrop.io';

export async function logout() {
  localStorage.removeItem('draftSpaceId');
  sessionStorage.removeItem('draftSpaceId');

  const apikeyId = localStorage.getItem('apikey_id');
  if (apikeyId) {
    await api.service('apikeys').remove(apikeyId);
    localStorage.removeItem('apikey_id');
    localStorage.removeItem('apikey');
  }
  await api.logout();
}
