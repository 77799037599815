import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../api';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function RenameMediaModal(props) {
  const { closeModal, media } = props;

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setProcessing(true);
    setError('');

    try {
      await api.service('media').patch(media._id, {
        title: inputRef.current.value,
      });

      // then close
      closeModal();
    } catch (err) {
      console.log('err', err);
      const message =
        err.code === 404
          ? 'You do not have permission to perform this action'
          : err.message;
      setError(message);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <Modal show onHide={closeModal}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton={!processing}>
          <Modal.Title as="h6">Rename</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="folderName">
            <Form.Label>Enter new file name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Untitled"
              ref={inputRef}
              defaultValue={media.title}
              required
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={processing}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={processing}>
            {processing ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Update</span>
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RenameMediaModal;
