import React, { useState, useRef, useEffect } from 'react';
import api from '../api';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function SpaceTitleModel(props) {
  const { closeModal, currentTitle = '', spaceId, onSuccess } = props;
  const isNew = spaceId === undefined;

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const newTitle = inputRef.current.value;

    if (!newTitle || newTitle.length === 0) {
      setError('Title is required');
      return;
    }

    setProcessing(true);
    setError('');

    try {
      if (spaceId) {
        const space = await api.service('spaces').patch(spaceId, {
          title: newTitle,
        });
        onSuccess && onSuccess(space);
      } else {
        const space = await api.service('spaces').create({
          title: newTitle,
          posts: [],
        });
        onSuccess && onSuccess(space);
      }

      // then close
      closeModal();
    } catch (err) {
      console.log('err', err);
      const message =
        err.code === 404
          ? 'You do not have permission to perform this action'
          : err.message;
      setError(message);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const inputLabel = isNew ? 'Enter a title' : 'Enter a new title';
  const submitLabel = isNew ? 'Create' : 'Update';

  return (
    <Modal show onHide={closeModal}>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="folderName">
            <Form.Label>{inputLabel}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Untitled"
              ref={inputRef}
              defaultValue={currentTitle}
              required
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={processing}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={processing}>
            {processing ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>{submitLabel}</span>
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SpaceTitleModel;
