function NoRecipientNotice(props) {
  return (
    <div className="pd-20 mg-t-10">
      <h3 className="mg-b-30 tx-light wd-200">
        Add a recipient to see options.
      </h3>
      <img
        src="/static/img/guide_add_recipient.png"
        width="280"
        className="bd"
      />
    </div>
  );
}

export default NoRecipientNotice;
