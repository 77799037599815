import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';

export default function useBackgroundDrop({ onDrop }) {
  return useDrop({
    accept: [NativeTypes.FILE],
    drop: onDrop,
    canDrop(item, monitor) {
      return monitor.isOver({ shallow: true });
    },
    collect(monitor) {
      return {
        isOver: monitor.isOver({ shallow: true }),
      };
    },
  });
}
