import * as Icon from 'react-feather';

function TagTitleView(props) {
  const { tag } = props;
  if (!tag) {
    return null;
  }

  const { name, permissionMode = 'everyone', customPermissions = [] } = tag;

  // visibility text
  let visibilityText = 'Visibile to Everyone';
  if (permissionMode === 'admin') {
    visibilityText = 'Visibile to Admins Only';
  } else if (permissionMode === 'custom') {
    if (customPermissions.length === 0) {
      visibilityText = 'Only visible to you';
    } else {
      visibilityText = `Visibile to ${customPermissions.length + 1} Users`;
    }
  }

  return (
    <div className="d-flex">
      <Icon.Tag color="#1b2e4b" size={16} />{' '}
      <span className="mg-l-10">{name}</span>
      <span className="tx-12 mg-l-20 tx-primary" style={{ cursor: 'pointer' }}>
        {visibilityText}
      </span>
    </div>
  );
}

export default TagTitleView;
