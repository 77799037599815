import { useSelector } from 'react-redux';
import { AuthUserSelector } from '../state/authUser';
import { SpaceViewSelector } from '../state/spaceView';
import { updateBackdropPermission } from '../api/backdrop';

import * as Icon from 'react-feather';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import { useReducer, useState } from 'react';
import { useActions } from './useActions';
import { WizardAction } from '../state/wizard';
import endsWith from 'lodash/endsWith';

function StepPermission(props) {
  const { recipients, collaborators } = props;
  const [recipient, ...otherRecipientEmails] = recipients;
  const allEmails = [...new Set([...otherRecipientEmails, ...collaborators])];

  const goToStepComplete = useActions(WizardAction.goToStepComplete);

  const orgDomainName = useSelector(AuthUserSelector.getOrgDomainName);
  const allCollaborators = allEmails.filter((email) =>
    endsWith(email, `@${orgDomainName}`)
  );
  const otherRecipients = allEmails.filter(
    (email) => !endsWith(email, `@${orgDomainName}`)
  );

  const hasCollaborators = allCollaborators.length > 0;
  const numCollaborators = allCollaborators.length;
  const collaboratorText =
    numCollaborators > 1 ? `${numCollaborators} team members` : 'a team member';

  const hasOtherRecipients = otherRecipients.length > 0;

  const allMembers = useSelector(SpaceViewSelector.getTeamMembers);
  const pendingMembers = allCollaborators.filter((email) => {
    const member = allMembers.find((m) => m.email === email);
    if (!member) {
      // this collaborator does not exists
      return true;
    }

    return !member.isVerified || Boolean(member.isInvitePending);
  });
  const licensedMembers = allMembers.filter(
    (member) =>
      member.isVerified &&
      !member.isInvitePending &&
      allCollaborators.includes(member.email) &&
      !pendingMembers.includes(member.email)
  );
  const numPendingMembers = pendingMembers.length;
  const hasPendingMembers = numPendingMembers > 0;

  const backdrop = useSelector(SpaceViewSelector.getSelectedBackdrop);

  // requireRecipientEmail
  const [requireRecipientEmail, setRequireRecipientEmail] = useState(
    backdrop?.requireRecipientEmail || !backdrop?.disableRecipientVerification
  );

  const handleRecipientVerificationOptionalChange = async (e) => {
    const isEnabled = e.target.checked;
    setRequireRecipientEmail(isEnabled);
    if (!isEnabled) {
      setDisableRecipientVerification(true);
    }
  };

  // disableRecipientVerification
  const [disableRecipientVerification, setDisableRecipientVerification] =
    useState(backdrop?.disableRecipientVerification);

  const handleRecipientVerificationChange = async (e) => {
    const isEnabled = e.target.checked;
    setDisableRecipientVerification(!isEnabled);
    // required
    if (isEnabled) {
      setRequireRecipientEmail(true);
    }
  };

  // downloadable
  const [downloadable, setDownloadable] = useState(backdrop?.downloadable);

  const handleDownloadableChange = async (e) => {
    const isEnabled = e.target.checked;
    setDownloadable(isEnabled);
  };

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');

  const handleContinue = async () => {
    setProcessing(true);
    setError('');

    try {
      // patch the backdrop
      await updateBackdropPermission(backdrop?.code, {
        requireRecipientEmail,
        disableRecipientVerification,
        downloadable,
      });

      setProcessing(false);
      goToStepComplete();
    } catch (ex) {
      console.log('cannot update the backdrop');
      console.error(ex);
      setError(ex.message);
      setProcessing(false);
    }
  };

  return (
    <div className="content pd-b-100">
      <div className="complete-screen">
        <header className="mg-t-10 mg-b-20">
          <h1 className="tx-light tx-26">Permissions</h1>
        </header>
      </div>
      <main>
        {hasPendingMembers && (
          <div className="pd-0">
            <Alert variant="danger">
              Warning: The following member(s) of your team are not licensed or
              activated yet. <br />
              <ul className="pd-l-30 mg-0">
                {pendingMembers.map((email) => (
                  <li>{email}</li>
                ))}
              </ul>
              <br />
              They might accidently open the backdrop link.{' '}
              <a
                href="https://help.backdrop.io/en/articles/6025324-outlook-plugin-multiple-recipients"
                target="_blank"
              >
                Learn more...
              </a>
            </Alert>
          </div>
        )}

        {hasOtherRecipients && (
          <div className="pd-0">
            <Alert variant="danger">
              <p>
                Warning: Backdrops have a unique link for each recipient.
                Sending to multiple recipients may prompt your customer to
                verify their email address when accessing the Backdrop.{' '}
                <a
                  href="https://help.backdrop.io/en/articles/6025324-outlook-plugin-multiple-recipients"
                  target="_blank"
                >
                  Learn more...
                </a>
              </p>
            </Alert>
          </div>
        )}

        <div className="custom-control custom-checkbox pd-t-10 mg-b-20">
          <input
            type="checkbox"
            className="custom-control-input"
            id="recipient-require-email"
            value={requireRecipientEmail}
            checked={requireRecipientEmail}
            onChange={handleRecipientVerificationOptionalChange}
          />
          <label className="custom-control-label" for="recipient-require-email">
            <strong>Require email to view</strong>
          </label>
          <div className="tx-gray-600 tx-12">
            Viewers must enter an email to view your Backdrop
          </div>
        </div>

        <div className="custom-control custom-checkbox mg-b-20">
          <input
            type="checkbox"
            className="custom-control-input"
            id="recipient-require-verification"
            value={!disableRecipientVerification}
            checked={!disableRecipientVerification}
            onChange={handleRecipientVerificationChange}
          />
          <label
            className="custom-control-label"
            for="recipient-require-verification"
          >
            <strong>Email Authentication</strong>
          </label>
          <div className="tx-gray-600 tx-12">
            Viewers must verify their identity via a secure link sent to their
            email prior to accessing your Backdrop
          </div>
        </div>

        <div className="custom-control custom-checkbox mg-b-20">
          <input
            type="checkbox"
            className="custom-control-input"
            id="recipient-downloadable"
            value={downloadable}
            checked={downloadable}
            onChange={handleDownloadableChange}
          />
          <label className="custom-control-label" for="recipient-downloadable">
            <strong>Allow downloading</strong>
          </label>
          <div className="tx-gray-600 tx-12">
            Allows viewers to download or print all documents inside your
            Backdrop
          </div>
        </div>

        {error && (
          <Alert variant="danger" className="mg-t-10">
            {error}
          </Alert>
        )}

        <div className="pos-fixed l-0 r-0 b-0 bg-white">
          <div className="pd-x-15 pd-b-30">
            <Button
              className="mg-t-30"
              variant="brand-02"
              block
              disabled={processing}
              onClick={handleContinue}
            >
              {processing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>
                  <span className="pd-r-5">Continue</span>
                  <Icon.ArrowRight />
                </span>
              )}
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default StepPermission;
