import { useSelector } from 'react-redux';
import { AuthUserSelector } from '../state/authUser';
import isEmpty from 'lodash/isEmpty';

export const defaultUploadConfig = {
  allowedFileTypes: [
    // pdf
    'application/pdf',
    // docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // pptx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // ai, eps, ps
    'application/postscript',
    // tif
    'image/tiff',
    // jpeg, jpg
    'image/jpeg',
    // png
    'image/png',
    // mp4
    'video/mp4',
  ],
  renderableFileTypes: [
    // pdf
    'application/pdf',
    // docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // pptx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // jpeg, jpg
    'image/jpeg',
    // png
    'image/png',
    // mp4
    'video/mp4',
  ],
  maxFileSize: 150 * 1024 * 1024,
  maxNumberOfFiles: 100,
};

export function getMergedConfig(remoteConfig) {
  if (isEmpty(remoteConfig)) {
    return defaultUploadConfig;
  }

  const allowedFileTypes = [
    ...defaultUploadConfig.allowedFileTypes,
    ...(remoteConfig.allowedFileTypes || []),
  ];
  const renderableFileTypes = [
    ...defaultUploadConfig.renderableFileTypes,
    ...(remoteConfig.renderableFileTypes || []),
  ];
  const maxFileSize =
    remoteConfig.maxFileSize || defaultUploadConfig.maxFileSize;
  const maxNumberOfFiles =
    remoteConfig.maxNumberOfFiles || defaultUploadConfig.maxNumberOfFiles;

  return {
    allowedFileTypes,
    renderableFileTypes,
    maxFileSize,
    maxNumberOfFiles,
  };
}

function useUploadConfig() {
  const remoteConfig = useSelector(AuthUserSelector.getUploadConfig);
  const config = getMergedConfig(remoteConfig);

  const isRenderable = (fileType) =>
    config.renderableFileTypes.includes(fileType);

  return [config, isRenderable];
}

export default useUploadConfig;
