import { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import * as outlook from '../api/outlook';
import * as gmail from '../api/gmail';

export function usePluginType() {
  const router = useRouter();
  if (router.pathname.startsWith('/gmail')) {
    return 'gmail';
  }

  return 'outlook';
}

export function usePluginLoadedFirstTime() {
  const pluginType = usePluginType();
  const [isFirstLoaded, setIsFirstLoaded] = useState(() => {
    if (pluginType === 'outlook') {
      try {
        const value = Office.context.roamingSettings.get('isFirstLoaded');
        if (value !== undefined && value !== null) {
          return Boolean(value);
        }
        return true;
      } catch (error) {
        return true;
      }
    }

    const value = localStorage.getItem('isFirstLoaded');
    if (value === 'false') {
      return false;
    }
    if (value !== undefined && value !== null) {
      return Boolean(value);
    }

    return true;
  });

  const markAsLoaded = useCallback(() => {
    if (pluginType === 'outlook') {
      Office.context.roamingSettings.set('isFirstLoaded', false);
      Office.context.roamingSettings.saveAsync(() => {
        setIsFirstLoaded(false);
      });
    } else {
      localStorage.setItem('isFirstLoaded', false);
      setIsFirstLoaded(false);
    }
  }, [setIsFirstLoaded, pluginType]);

  return [isFirstLoaded, markAsLoaded];
}

// recipients
function useOutlookRecipients() {
  const [recipients, setRecipients] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const syncRecipients = useCallback(
    async (event) => {
      const checkToField = event ? event.changedRecipientFields?.to : true;
      const checkCcField = event ? event.changedRecipientFields?.cc : true;

      let toContacts = [];
      let ccContacts = [];
      let mainContact = recipients[0];
      if (checkToField) {
        toContacts = await outlook.getToContacts();
      }
      if (checkCcField) {
        ccContacts = await outlook.getCcContacts();
      }

      if (checkToField) {
        setRecipients(toContacts);
        mainContact = toContacts[0];
      }
      if (checkCcField) {
        const filteredCcContacts = ccContacts.filter((c) => c !== mainContact);
        setCollaborators(filteredCcContacts);
      }
    },
    [recipients, setRecipients, setCollaborators]
  );

  useEffect(() => {
    syncRecipients();
  }, []);

  useEffect(() => {
    outlook.registerRecipientsChangeHandlerAsync(syncRecipients);

    return () => {
      outlook.deregisterRecipientsChangeHandlerAsync(syncRecipients);
    };
  }, [syncRecipients]);

  return [recipients, collaborators];
}

function useGmailRecipients() {
  const [recipients, setRecipients] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const syncRecipients = useCallback(
    async (event) => {
      console.log('gmail::syncRecipients()', event);
      const checkToField = event
        ? Boolean(event.to.added.length > 0 || event.to.removed.length > 0)
        : true;
      const checkCcField = event
        ? Boolean(event.cc.added.length > 0 || event.cc.removed.length > 0)
        : true;

      let toContacts = [];
      let ccContacts = [];
      let mainContact = recipients[0];
      if (checkToField) {
        toContacts = await gmail.getToContacts();
      }
      if (checkCcField) {
        ccContacts = await gmail.getCcContacts();
      }

      if (checkToField) {
        setRecipients(toContacts);
        mainContact = toContacts[0];
      }
      if (checkCcField) {
        const filteredCcContacts = ccContacts.filter((c) => c !== mainContact);
        setCollaborators(filteredCcContacts);
      }
    },
    [recipients, setRecipients, setCollaborators]
  );

  useEffect(() => {
    syncRecipients();
  }, []);

  useEffect(() => {
    gmail.registerRecipientsChangeHandlerAsync(syncRecipients);

    return () => {
      gmail.deregisterRecipientsChangeHandlerAsync(syncRecipients);
    };
  }, [syncRecipients]);

  return [recipients, collaborators];
}

export function useRecipients() {
  const pluginType = usePluginType();
  if (pluginType === 'gmail') {
    return useGmailRecipients();
  }

  return useOutlookRecipients();
}

export function useActiveBackdropInEmailThread() {
  const pluginType = usePluginType();
  const [activeBackdropCode, setActiveBackdropCode] = useState(null);

  useEffect(() => {
    if (pluginType === 'gmail') {
      gmail.getActiveBackdropCode().then((code) => {
        if (!code) {
          setActiveBackdropCode('');
        } else {
          setActiveBackdropCode(code);
        }
      });
    } else {
      outlook.getActiveBackdropCode().then((code) => {
        if (!code) {
          setActiveBackdropCode('');
        } else {
          setActiveBackdropCode(code);
        }
      });
    }
  }, [pluginType]);

  return activeBackdropCode;
}

export function usePluginHost() {
  const pluginType = usePluginType();

  const resetSettings =
    pluginType === 'gmail' ? gmail.resetSettings : outlook.resetSettings;
  const insertUrlInMailBody =
    pluginType === 'gmail'
      ? gmail.insertUrlInMailBody
      : outlook.insertUrlInMailBody;

  const getActiveBackdropCode =
    pluginType === 'gmail'
      ? gmail.getActiveBackdropCode
      : outlook.getActiveBackdropCode;

  const isInAnEmailThread =
    pluginType === 'gmail'
      ? gmail.isInAnEmailThread
      : outlook.isInAnEmailThread;

  const closeDrawer =
    pluginType === 'gmail' ? gmail.closeDrawer : outlook.closeDrawer;

  const getSubject =
    pluginType === 'gmail' ? gmail.getSubject : outlook.getSubject;

  return {
    resetSettings,
    insertUrlInMailBody,
    getActiveBackdropCode,
    isInAnEmailThread,
    closeDrawer,
    getSubject,
  };
}
