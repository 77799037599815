import * as React from 'react';

const initialState = {
  result: null,
  error: null,
  loading: false,
  completed: false,
  failed: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'started':
      return { ...initialState, loading: true };
    case 'completed':
      return {
        result: action.payload,
        loading: false,
        failed: false,
        completed: true,
      };
    case 'failed':
      return {
        error: action.payload,
        loading: false,
        failed: true,
        completed: false,
      };
    default:
      throw new Error();
  }
}

function useApi(initFn, options = {}) {
  const { autorun = false, args = [], onSuccess, onError } = options;
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const startApiCall = async () => {
    dispatch({
      type: 'started',
    });

    try {
      const result = await initFn.apply(null, args);
      onSuccess && onSuccess(result);
      dispatch({
        type: 'completed',
        payload: result,
      });
    } catch (error) {
      onError && onError(error);
      dispatch({
        type: 'failed',
        payload: error,
      });
    }
  };

  React.useEffect(() => {
    if (!autorun) {
      return;
    }

    startApiCall();
  }, [initFn]);

  return [state, startApiCall];
}

export default useApi;
