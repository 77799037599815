// outlook api
const fetch = require('isomorphic-unfetch');

const viewerHostnames = [
  'backdrop-dev-enduser.herokuapp.com',
  'view.dev.backdrop.io',
  'view.backdrop.io',
  'view.backdrop.cc',
];
const backdropCodeRegex = /^\/[a-zA-Z0-9].+$/;

export function getToContacts() {
  return new Promise(function (resolve) {
    Office.context.mailbox.item.to.getAsync(function (emailAddressDetails) {
      if (emailAddressDetails.value.length > 0) {
        resolve(emailAddressDetails.value.map((entry) => entry.emailAddress));
      } else {
        resolve([]);
      }
    });
  });
}

export function getCcContacts() {
  return new Promise(function (resolve) {
    Office.context.mailbox.item.cc.getAsync(function (emailAddressDetails) {
      if (emailAddressDetails.value.length > 0) {
        resolve(emailAddressDetails.value.map((entry) => entry.emailAddress));
      } else {
        resolve([]);
      }
    });
  });
}

export async function registerRecipientsChangeHandlerAsync(callback) {
  Office.context.mailbox.item.addHandlerAsync(
    Office.EventType.RecipientsChanged,
    callback
  );
}

export async function deregisterRecipientsChangeHandlerAsync(callback) {
  Office.context.mailbox.item.removeHandlerAsync(
    Office.EventType.RecipientsChanged,
    callback
  );
}

// subject
export function getSubject() {
  return new Promise(function (resolve) {
    Office.context.mailbox.item.subject.getAsync(function (asyncResult) {
      resolve(asyncResult.value);
    });
  });
}

// attachment
export async function getAttachmentsAsync() {
  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.getAttachmentsAsync(function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        return resolve(result.value);
      }

      return reject(result.error);
    })
  );
}

export async function getAttachmentContentAsync(attachmentId) {
  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.getAttachmentContentAsync(
      attachmentId,
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          return resolve(result.value);
        }

        return reject(result.error);
      }
    )
  );
}

export async function removeAttachmentAsync(attachmentId) {
  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.removeAttachmentAsync(
      attachmentId,
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          return resolve(result.value);
        }

        return reject(result.error);
      }
    )
  );
}

export async function registerAttachmentsChangeHandlerAsync(callback) {
  Office.context.mailbox.item.addHandlerAsync(
    Office.EventType.AttachmentsChanged,
    callback
  );
}

export async function deregisterAttachmentsChangeHandlerAsync(callback) {
  Office.context.mailbox.item.removeHandlerAsync(
    Office.EventType.AttachmentsChanged,
    callback
  );
}

export function getActiveBackdropCode() {
  console.log('getActiveBackdropCode');
  if (!Office.context.requirements.isSetSupported('Mailbox', '1.5')) {
    return Promise.resolve('');
  }

  return new Promise((resolve) =>
    Office.context.mailbox.getCallbackTokenAsync(
      { isRest: true },
      async function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const ewsId = Office.context.mailbox.item.conversationId;

          if (!ewsId) {
            return resolve('');
          }

          const token = result.value;
          const restId = Office.context.mailbox.convertToRestId(
            ewsId,
            Office.MailboxEnums.RestVersion.v2_0
          );
          const url =
            Office.context.mailbox.restUrl +
            `/v2.0/me/messages/?$select=Body&$filter=IsDraft eq false and ConversationId eq '${restId}'`;

          try {
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });
            const json = await response.json();

            let backdropCode = '';
            if (json.value && json.value.length > 0) {
              for (const messages of json.value) {
                const html = messages.Body.Content;

                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(html, 'text/html');

                htmlDoc.querySelectorAll('a').forEach((node) => {
                  if (backdropCode !== '') {
                    return;
                  }

                  const href = node.getAttribute('href');
                  if (href) {
                    const url = new URL(href);
                    if (
                      viewerHostnames.includes(url.hostname) &&
                      backdropCodeRegex.exec(url.pathname)
                    ) {
                      backdropCode = url.pathname.replace('/', '');
                    }
                  }
                });

                if (backdropCode !== '') {
                  return resolve(backdropCode);
                }
              }

              if (backdropCode === '') {
                return resolve('');
              }
            } else {
              resolve('');
            }
          } catch (ex) {
            resolve('');
          }
        }

        return resolve('');
      }
    )
  );
}

export async function isInAnEmailThread() {
  if (!Office.context.requirements.isSetSupported('Mailbox', '1.10')) {
    return Promise.resolve(false);
  }

  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.getComposeTypeAsync(function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        return resolve(
          result.value.composeType === Office.MailboxEnums.ComposeType.Reply
        );
      }

      return resolve(false);
    })
  );
}

export async function closeDrawer() {
  Office.context.ui.closeContainer();
}

// settings
export function resetSettings() {
  return new Promise(function (resolve) {
    Office.context.roamingSettings.set('isFirstLoaded', true);
    Office.context.roamingSettings.saveAsync(() => {
      resolve();
    });
  });
}

// mail function
function buildBackdropLink(code, title, url) {
  return `<a alt="${code}" title="${title}" href="${url}" id="LPNoLP">${title}</a>`;
}

export function insertUrlInMailBody(code, title, url) {
  const content = buildBackdropLink(code, title, url);
  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.body.setSelectedDataAsync(
      content,
      { coercionType: Office.CoercionType.Html },
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          return resolve(result.value);
        }

        return reject(result.error);
      }
    )
  );
}

export function getBodyHtml() {
  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.body.getAsync(
      Office.CoercionType.Html,
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          return resolve(result.value);
        }

        return reject(result.error);
      }
    )
  );
}

function setBodyHtml(content) {
  return new Promise((resolve, reject) =>
    Office.context.mailbox.item.body.setAsync(
      content,
      { coercionType: Office.CoercionType.Html },
      function (result) {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          return resolve(result.value);
        }

        return reject(result.error);
      }
    )
  );
}

export async function updateUrlInMailBody(code, title, url) {
  const html = await getBodyHtml();

  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/html');

  htmlDoc.querySelectorAll('a').forEach((node) => {
    if (node.getAttribute('href') === url) {
      node.setAttribute('title', title);
      node.setAttribute('alt', code);
      node.innerText = title;
    }
  });

  const newHtml = htmlDoc.body.innerHTML;

  await setBodyHtml(newHtml);
}
