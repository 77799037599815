import * as React from 'react';
import cx from 'classnames';
import Gravatar from 'react-gravatar';
import trim from 'lodash/trim';

const COLORS = [
  'bg-primary',
  'bg-secondary',
  'bg-success',
  'bg-warning',
  'bg-danger',
  'bg-info',
  'bg-indigo',
  'bg-purple',
  'bg-pink',
  'bg-teal',
  'bg-orange',
];

const getAvatarColor = (name) => {
  const num = name.split('').reduce((arr, next) => arr + next.charCodeAt(0), 0);
  const index = num % COLORS.length;
  return COLORS[index];
};

const getInitial = (name) => trim(name, '-_-[]')[0];

const Avatar = ({
  image = '',
  name = '',
  email = '',
  online = false,
  offline = false,
  dead = false,
  inGroup = false,
  size = '',
  ...otherProps
}) => (
  <div
    className={cx('avatar tx-left', {
      'avatar-xs': size === 'xs',
      'avatar-sm': size === 'sm',
      'avatar-md': size === 'md',
      'avatar-lg': size === 'lg',
      'avatar-xl': size === 'xl',
      'avatar-xxl': size === 'xxl',
      'avatar-online': online,
      'avatar-offline': offline,
      'd-inline-block': inGroup,
    })}
    style={{
      marginLeft: inGroup ? -5 : 0,
      border: inGroup ? '3px solid white' : 'none',
      borderRadius: '50%',
    }}
    {...otherProps}
  >
    <span
      className={`avatar-initial rounded-circle ${
        dead ? 'gray' : getAvatarColor(name)
      }`}
    >
      {getInitial(name)}
    </span>
    {image ? (
      <img src={image} className="rounded-circle" />
    ) : (
      email && (
        <Gravatar email={email} className="rounded-circle" default="blank" />
      )
    )}
  </div>
);

export default Avatar;
