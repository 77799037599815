import React from 'react';

import * as Icon from 'react-feather';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import SpaceContent from './SpaceContent';
import PostContextMenu from './PostContextMenu';
import RenameSpaceButton from './RenameSpaceButton';

import { useSelector } from 'react-redux';
import { SpaceViewAction, SpaceViewSelector } from '../state/spaceView';
import { useActions } from './useActions';
import DocumentContextMenu from './DocumentContextMenu';

function StepDraftSpace({ recipient }) {
  const space = useSelector(SpaceViewSelector.getDraftSpace);
  const hasSpace = Boolean(space && space._id);

  return (
    <div>
      <PostContextMenu />
      <DocumentContextMenu />
      {hasSpace && <SpaceContent space={space} recipient={recipient} />}
    </div>
  );
}

export default StepDraftSpace;
