import cx from 'classnames';
import useBackgroundDrop from './useBackgroundDrop';

function SpaceBackgroundUploader(props) {
  const { children, uppy, onDrop, hasTemplateSelect = false } = props;

  // drop handling
  const [collectedProps, drop] = useBackgroundDrop({
    onDrop: async (item, monitor) => {
      uppy.reset();
      onDrop && onDrop();

      if (item.files) {
        item.files.forEach((file) => {
          try {
            uppy.addFile({
              name: file.name,
              type: file.type,
              data: file,
              meta: {
                relativePath:
                  file.webkitRelativePath === ''
                    ? undefined
                    : file.webkitRelativePath,
              },
              source: 'react:DashboardModal',
              isRemote: false,
            });
          } catch (err) {
            console.log('cannot add file');
          }
        });
      }
    },
  });

  const { isOver } = collectedProps;

  return (
    <div className={cx('background-view', { isOver })} ref={drop}>
      {children}

      {isOver && (
        <div className="hint-container">
          <div className="upload-icon">
            <img
              className="animated pulse infinite"
              src="/static/img/upload.svg"
            />
          </div>
          <div className="hint-text">
            <span>Drop files to add...</span>
          </div>
        </div>
      )}

      <style jsx>{`
        @keyframes pulse {
          0% {
            transform: scale(0.8);
          }

          70% {
            transform: scale(1);
          }

          100% {
            transform: scale(0.8);
          }
        }

        .background-view {
          position: static;

          border: 1px dashed #0168fb;
          background-color: #fafcfe;
          border-radius: 4px;
          min-height: 150px;

          height: calc(100vh - ${hasTemplateSelect ? 320 : 280}px);
          max-height: calc(100vh - ${hasTemplateSelect ? 320 : 280}px);
          overflow: scroll;
        }

        .isOver {
          border-color: #0168fa;
          background-color: #eaf0fd;
        }
        .hint-container {
          position: absolute;
          bottom: 20px;
          height: 100px;
          left: calc(50% - 70px);
          width: 140px;
          text-align: center;
          margin: 0 auto;
        }
        .upload-icon img {
          margin-bottom: 10px;
          animation: pulse 1s infinite;
        }
        .hint-text {
          background-color: #3e8eff;
          border-radius: 5px;
          color: white;
          padding: 10px;
          text-align: center;

          box-shadow: 0 0 6px #888888;
        }
      `}</style>
    </div>
  );
}

export default SpaceBackgroundUploader;
