import api from './';

export async function refreshThumbnail(files) {
  const tasks = files.map((file) =>
    api.service(`media/${file._id}/thumbnail`).create({})
  );
  return await Promise.all(tasks);
}

export async function downloadFile(file) {
  // first get the url, godamnit
  const { downloadUrl2 } = await api.service('media').get(file._id);
  // then download the file
  window.location = downloadUrl2;
}

export function starFile(file) {
  return api.service('users/me/sticky-files').create({ mediaId: file._id });
}

export function unstarFile(file) {
  return api.service('users/me/sticky-files').remove(file._id);
}
