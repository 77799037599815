import api, { makeApi } from '../api/feathers';
import * as Sentry from '@sentry/nextjs';

import { connect } from 'react-redux';
import * as React from 'react';
import Link from 'next/link';
import Cookie from 'js-cookie';

import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import get from 'lodash/get';

import { AuthUserAction } from '../state/authUser';

const connectToRedux = connect((state) => ({}), {
  setAuthUser: AuthUserAction.setUser,
});

const SigninForm = ({
  values,
  touched,
  status,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) => (
  <div className="wd-100p mg-t-50">
    <h3 className="tx-color-01 mg-b-5">Sign In</h3>
    <p className="tx-color-03 tx-16 mg-b-40">Please sign in to continue.</p>
    {status && (
      <Alert variant="danger">
        <span>{status.message}</span>
        {status.showReverify && (
          <span>
            <br />
            <Link shallow href={`/reverify?email=${values.email}`}>
              <a>Resend Verify Email</a>
            </Link>
          </span>
        )}
      </Alert>
    )}
    <Form method="post" noValidate onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="you@example.com"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          name="email"
          isInvalid={touched.email && !!errors.email}
          disabled={isSubmitting}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <div className="d-flex justify-content-between mg-b-5">
          <Form.Label className="mg-b-0-f">Password</Form.Label>
          {/* <Link shallow href="/forgot">
            <a tabIndex="100" className="tx-13">
              Forgot password?
            </a>
          </Link> */}
        </div>
        <Form.Control
          autoComplete="off"
          type="password"
          placeholder="something super secret"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          name="password"
          isInvalid={touched.password && !!errors.password}
          disabled={isSubmitting}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password}
        </Form.Control.Feedback>
      </Form.Group>

      <Button
        className="mg-t-40"
        variant="brand-02"
        type="submit"
        disabled={isSubmitting}
        block
      >
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <span>Sign In</span>
        )}
      </Button>
    </Form>
    {/* <div className="divider-text">or</div>
    <button className="btn btn-outline-facebook btn-block">
      Sign In With Facebook
    </button>
    <button className="btn btn-outline-twitter btn-block">
      Sign In With Twitter
    </button> */}
    {/* <div className="tx-13 mg-t-20 tx-center">
      <span>Don't have an account? </span>
      <Link shallow href="/signup">
        <a>Create an Account</a>
      </Link>
    </div> */}
  </div>
);

const SigninSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(8, 'Too Short!').required('Required'),
});

const enhance = withFormik({
  displayName: 'SigninForm',
  validationSchema: SigninSchema,
  mapPropsToValues: ({ email = '' }) => ({
    email,
    password: '',
  }),
  handleSubmit: async (
    values,
    { setSubmitting, setFieldError, setStatus, props }
  ) => {
    const { onSuccess, setAuthUser } = props;
    const { email, password } = values;
    const deviceId = Cookie.get('deviceId');
    try {
      const data = { strategy: 'local', email, password };
      if (deviceId) {
        data.deviceId = deviceId;
      }

      // 1. authenticate with local strategy
      const resp = await api.authenticate(data);
      api.authentication.setAccessToken(resp.accessToken);

      // 2. create new apikey
      const resp2 = await api
        .service('apikeys')
        .create({ notes: 'outlook_web_v1.1.0' });

      // 3. save to localstorage
      localStorage.setItem('apikey_id', resp2._id);
      localStorage.setItem('apikey', resp2.apikey);

      setAuthUser(resp.user);
      onSuccess && (await onSuccess(resp.user));
    } catch (error) {
      console.log(error);
      const emailNotVerified = get(error, 'data.emailNotVerified');
      if (error.code >= 500) {
        // general error
        setStatus({ message: `An error occurred (code: ${error.code})` });
      } else {
        setStatus({
          message: `${error.message} (code: ${error.code})`,
          showReverify: emailNotVerified,
        });
      }

      Sentry.withScope((scope) => {
        scope.setExtra('action', 'sign in');
        scope.setExtra('email', values.email);
        Sentry.captureException(error);
      });
    }
    setSubmitting(false);
  },
  validateOnBlur: true,
  validateOnChange: false,
});

export default connectToRedux(enhance(SigninForm));
