import { useState, useCallback } from 'react';

export default function useDialogState(initialShow, initialData = {}) {
  const [show, setShow] = useState(initialShow);
  const [data, setData] = useState(initialData);

  const openDialog = useCallback((data) => {
    setData(data);
    setShow(true);
  });
  const closeDialog = useCallback(() => {
    setShow(false);
    setData(initialData);
  });

  return [show, openDialog, closeDialog, data];
}
