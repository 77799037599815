import { refreshThumbnail, downloadFile } from '../api/media';
import { useState } from 'react';
import useDialogState from './useDialogState';
import { ADMIN_CP_URL } from './config';
import { useActions } from './useActions';
// import { NotificationAction } from '../state/notification';

import * as Icon from 'react-feather';
import { ContextMenu, MenuItem } from 'react-contextmenu';

function DocumentContextMenu() {
  const [context, setContext] = useState({});

  // handle download
  const handleDownload = async () => {
    if (context.file) {
      await downloadFile(context.file);
    }
  };

  // handle right click
  const handleContextMenuOpen = (e) => {
    const file = e.detail.data.file;
    const editable = e.detail.data.editable;

    setContext({ file, editable });
  };

  const { file, post } = context;

  const handleMenuClick = (e, data) => {
    switch (data.action) {
      case 'open':
        window.open(`${ADMIN_CP_URL}/media/${file._id}/viewer`);
        break;

      default:
        break;
    }
  };

  const isIntegration = file && file.type === 'integration';

  return (
    <div>
      <ContextMenu id="document_context_menu" onShow={handleContextMenuOpen}>
        <MenuItem data={{ action: 'open' }} onClick={handleMenuClick}>
          <Icon.ExternalLink /> Open Permalink
        </MenuItem>
        <MenuItem
          data={{ action: 'download' }}
          onClick={handleDownload}
          disabled={isIntegration}
        >
          <Icon.Download /> Download
        </MenuItem>
      </ContextMenu>
    </div>
  );
}

export default DocumentContextMenu;
