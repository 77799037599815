import React from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-feather';
import { usePluginHost } from './usePluginHost';
import { updateSpaceTitle } from '../api/space';
import { NotificationAction } from '../state/notification';
import { useActions } from './useActions';

function SyncSubjectButton({ spaceId }) {
  const { getSubject } = usePluginHost();
  const showNotification = useActions(NotificationAction.show);
  // handle sync
  const handleSyncSubject = async () => {
    const subject = await getSubject();
    if (subject && subject.length > 0) {
      // update space
      try {
        await updateSpaceTitle(spaceId, subject);
      } catch (error) {
        showNotification({
          title: 'Error',
          body: 'Failed to update the backdrop. Please try again.',
        });
      }
    }
  };

  return (
    <Button
      variant="outline-primary"
      className="btn-icon"
      onClick={handleSyncSubject}
      disabled={!spaceId}
    >
      <Icon.RefreshCcw />
    </Button>
  );
}

export default SyncSubjectButton;
