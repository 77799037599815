import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';
import { LibraryViewAction, LibraryViewSelector } from '../state/libraryView';

import LibraryContentMediaHeader from './LibraryContentMediaHeader';
import LibraryBackgroundView from './LibraryBackgroundView';
import LibraryContentMediaView from './LibraryContentMediaView';
import LibraryFooter from './LibraryFooter';

function LibraryContent({ spaceId }) {
  const initLibrary = useActions(LibraryViewAction.init);
  const goToFolder = useActions(LibraryViewAction.goToFolder);

  React.useEffect(() => {
    initLibrary({ viewMode: 'folder', item: null });
  }, [initLibrary]);

  React.useEffect(() => {
    goToFolder(null);
  }, [goToFolder]);

  return (
    <div className="content-body pd-0">
      <div className="filemgr-wrapper filemgr-wrapper-two">
        <div className="filemgr-sidebar">
          {/* <LibrarySidebarHeader /> */}
          {/* <LibrarySidebar /> */}
        </div>
        <div className="filemgr-content" id="filemgr-container">
          <LibraryContentMediaHeader />
          <LibraryBackgroundView>
            <LibraryContentMediaView />
          </LibraryBackgroundView>
        </div>
      </div>

      <LibraryFooter spaceId={spaceId} />
    </div>
  );
}

export default LibraryContent;
