import dynamic from 'next/dynamic';
import React, { useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { chunk, filter, orderBy } from 'lodash';
import { useActions } from './useActions';
import find from 'lodash/find';

import Alert from 'react-bootstrap/Alert';
import { LibraryViewSelector, LibraryViewAction } from '../state/libraryView';
import { AuthUserSelector } from '../state/authUser';
import DocumentItem from './DocumentItem';
import FolderItem from './FolderItem';
import ParentFolderItem from './ParentFolderItem';

function LibraryContentMediaView(props) {
  const visibleFolders = useSelector(LibraryViewSelector.getVisibleFolders);
  const visibleFiles = useSelector(LibraryViewSelector.getVisibleFiles);
  const currentFolder = useSelector(LibraryViewSelector.getCurrentFolder);

  const parentFolder = currentFolder ? currentFolder.parent : undefined;
  const showParentFolder = parentFolder !== undefined;

  const folderRows = chunk(visibleFolders, 1);
  const fileRows = chunk(visibleFiles, 4);

  const stickyFiles = useSelector(AuthUserSelector.getStickyFiles) || [];
  const isStickyFile = useCallback(
    (file) => stickyFiles.indexOf(file._id) > -1,
    [stickyFiles]
  );

  const fetchError = useSelector(LibraryViewSelector.getMediaFetchError);

  const selectedMedia = useSelector(LibraryViewSelector.getSelectedMedia) || [];
  const addToSelection = useActions(LibraryViewAction.addToSelection);
  const removeFromSelection = useActions(LibraryViewAction.removeFromSelection);

  const handleToggleSelectFile = (fileId) => {
    const selected = Boolean(find(selectedMedia, { _id: fileId }));
    const file = find(visibleFiles, { _id: fileId });
    if (file) {
      if (selected) {
        removeFromSelection(file);
      } else {
        addToSelection(file);
      }
    }
  };

  return (
    <PerfectScrollbar className="filemgr-content-body">
      <div className="filemgr-content-body" data-cy="media-content-body">
        {fetchError && (
          <div
            className="pd-20 wd-500"
            style={{ textAlign: 'center', margin: '0 auto' }}
          >
            <Alert variant="danger">Error: {fetchError.message}</Alert>
          </div>
        )}
        <div className="pd-t-20 pd-b-100">
          <div className="pd-l-10 pd-r-10 pd-xl-l-20 pd-xl-r-20 folder-view">
            {showParentFolder && (
              <div className="row row-xs justify-content-start">
                <div className="col-12" style={{ padding: 8 }}>
                  <ParentFolderItem folder={parentFolder} />
                </div>
              </div>
            )}
            {folderRows.map((row, index) => (
              <div className="row row-xs justify-content-start" key={index}>
                {row.map((folder) => (
                  <div
                    className="col-12"
                    key={folder._id}
                    style={{ padding: 8 }}
                  >
                    <FolderItem folder={folder} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="pd-l-10 pd-r-10 pd-xl-l-20 pd-xl-r-20 pd-t-10 document-view">
            {fileRows.map((row, index) => (
              <div className="row row-xs justify-content-start" key={index}>
                {row.map((file) => (
                  <div
                    className="col-6 col-sm-4 col-md-3"
                    key={file._id}
                    style={{ padding: 8 }}
                  >
                    <DocumentItem
                      file={file}
                      sticky={isStickyFile(file)}
                      lazyload
                      selected={Boolean(find(selectedMedia, { _id: file._id }))}
                      onChange={handleToggleSelectFile}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  );
}

export default LibraryContentMediaView;
