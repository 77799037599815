import { useEffect } from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import * as Sentry from '@sentry/nextjs';

import AppHeader from './AppHeader';
import NoRecipientNotice from './NoRecipientNotice';
import StepBackdrop from './StepBackdrop';
import StepComplete from './StepComplete';

import { useActions } from './useActions';
import { useSelector } from 'react-redux';
import { WizardAction, WizardSelector } from '../state/wizard';
import { SpaceViewAction, SpaceViewSelector } from '../state/spaceView';
import { AuthUserSelector } from '../state/authUser';
import StepSelectBackdrop from './StepSelectBackdrop';
import StepDraftSpace from './StepDraftSpace';
import { usePluginHost, useRecipients } from './usePluginHost';
import startsWith from 'lodash/startsWith';
import StepPermission from './StepPermission';

function MainContent(props) {
  const [recipients, collaborators] = useRecipients();
  const hasRecipient = recipients.length > 0;
  const recipient = recipients[0];

  // fetch data
  const orgId = useSelector(AuthUserSelector.getOrgId);
  const fetchBackdrops = useActions(SpaceViewAction.fetchBackdrops);
  const hasActiveBackdrops = useSelector(SpaceViewSelector.hasActiveBackdrops);

  const { isInAnEmailThread, getActiveBackdropCode, getSubject } =
    usePluginHost();

  useEffect(() => {
    if (recipient && orgId) {
      isInAnEmailThread()
        .then(
          async (isReply) => {
            console.log('isReply', isReply);
            if (isReply) {
              const backdropCode = await getActiveBackdropCode();
              console.log('backdropCode', backdropCode);
              if (!backdropCode) {
                // impossible omg
                fetchBackdrops(recipient, orgId, {
                  mode: 'compose',
                  defaultSubject: 'Sales Package',
                });
              } else {
                fetchBackdrops(recipient, orgId, {
                  mode: 'reply',
                  backdropCode,
                  defaultSubject: 'Sales Package',
                });
              }
            } else {
              const emailSubject = await getSubject();
              const shouldSync =
                emailSubject && !startsWith(emailSubject.toLowerCase(), 're:');
              const defaultSubject = shouldSync
                ? emailSubject
                : 'Sales Package';
              fetchBackdrops(recipient, orgId, {
                mode: 'compose',
                defaultSubject,
              });
            }
          },
          (reason) => {
            console.log('Rejected', reason);
          }
        )
        .catch((ex) => {
          console.log('An error occurred');
          console.error(ex);
          Sentry.captureException(ex);
        });
    }
  }, [recipient, orgId]);

  const { loading, completed, failed } = useSelector(
    SpaceViewSelector.getFetchStatus
  );

  // progress step
  const activeStep = useSelector(WizardSelector.getActiveStep);
  const showStepSelectBackdrop =
    completed && activeStep === 'home' && hasActiveBackdrops;
  const showStepBackdrop = completed && activeStep === 'backdrop';
  const showStepDraft =
    completed &&
    (activeStep === 'draft' || (activeStep === 'home' && !hasActiveBackdrops));
  const showStepPermission = completed && activeStep === 'permission';
  const showStepComplete = completed && activeStep === 'complete';

  return (
    <>
      <AppHeader />
      {hasRecipient ? (
        <>
          {loading && (
            <div className="pd-15">
              <Spinner
                as="span"
                variant="primary"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}

          {failed && (
            <div className="pd-15">
              <Alert variant="danger">
                Unable to fetch backdrops for <b>{recipient}</b>.{' '}
                <a href="#" onClick={() => fetchBackdrops(recipient)}>
                  Retry
                </a>
              </Alert>
            </div>
          )}

          {showStepSelectBackdrop && (
            <StepSelectBackdrop recipient={recipient} />
          )}
          {showStepBackdrop && <StepBackdrop />}
          {showStepDraft && <StepDraftSpace recipient={recipient} />}
          {showStepPermission && (
            <StepPermission
              recipients={recipients}
              collaborators={collaborators}
            />
          )}
          {showStepComplete && (
            <StepComplete
              recipients={recipients}
              collaborators={collaborators}
            />
          )}
        </>
      ) : (
        <NoRecipientNotice />
      )}
    </>
  );
}

export default MainContent;
