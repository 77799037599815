import api from '../api';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-feather';
import { NotificationAction } from '../state/notification';
import { useActions } from './useActions';

function SaveDefaultTemplateButton({ templateId = '' }) {
  const showNotification = useActions(NotificationAction.show);
  const handleSave = async () => {
    try {
      await api.service('users').patch('me', {
        defaultTemplateId: templateId,
      });
      showNotification({
        title: 'Success',
        body: 'Default template updated',
      });
    } catch (error) {
      showNotification({
        title: 'Error',
        body: 'An error occurred. Please try again.',
      });
      console.log(error);
    }
  };
  return (
    <Button variant="outline-primary" className="btn-icon" onClick={handleSave}>
      <Icon.CheckCircle />
    </Button>
  );
}

export default SaveDefaultTemplateButton;
