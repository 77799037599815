import {
  refreshThumbnail,
  downloadFile,
  starFile,
  unstarFile,
} from '../api/media';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as Icon from 'react-feather';
import { LibraryViewSelector, LibraryViewAction } from '../state/libraryView';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import useDialogState from './useDialogState';
import LibraryBreadcrumb from './LibraryBreadcrumb';

import { useActions } from './useActions';
import { detectTouchscreen } from './helpers';
import { useRouter } from 'next/router';

function LibraryContentMediaHeader(props) {
  const keyword = useSelector(LibraryViewSelector.getKeyword);

  const search = useActions(LibraryViewAction.search);

  const handleKeywordChange = (e) => {
    const keyword = e.target.value;
    search(keyword);
  };

  return (
    <div className="filemgr-content-header bg-gray-200">
      <LibraryBreadcrumb />
      <nav className="nav mg-l-auto">
        <div className="files-search-container d-none d-sm-flex">
          <Icon.Search className="feather-search" />
          <div className="search-form">
            <input
              type="search"
              className="form-control"
              placeholder="Filter files..."
              value={keyword}
              onChange={handleKeywordChange}
            />
          </div>
        </div>
        <style jsx global>{`
          .toolbar {
            margin-top: 3px;
            margin-right: 10px;
            position: relative;
          }
          .toolbar .btn-icon {
            border: none;
          }
          .toolbar span {
            margin-right: 8px;
            margin-top: 2px;
          }
          .toolbar .btn {
            margin-left: 3px;
            margin-right: 3px;
          }
          .files-search-container {
            border: 1px solid #ccc;
            border-radius: 6px;
            padding-left: 8px;
            padding-right: 8px;
          }
        `}</style>
      </nav>
    </div>
  );
}

export default LibraryContentMediaHeader;
