import * as Icon from 'react-feather';

function DraftPlaceholderView(props) {
  const { onBrowse, minimize = false, canBrowse = false } = props;

  const handleBrowse = (e) => {
    e.preventDefault();
    onBrowse();
  };

  return (
    <div className="draft-placeholder d-flex justify-content-center">
      <div className="align-self-center">
        <div className="mg-b-10">
          <Icon.UploadCloud color="#0168fb" size={48} />
        </div>
        <p className="tx-gray-600 tx-14 mg-0 pd-0">
          <span>Drag & drop documents to upload</span>
          <br />
          {canBrowse && (
            <span>
              OR{' '}
              <a href="#" onClick={handleBrowse}>
                browse files
              </a>{' '}
            </span>
          )}
        </p>
      </div>
      <style jsx>{`
        .draft-placeholder {
          width: 80%;
          height: ${minimize ? '60%' : '100%'};
          margin: 0 auto;
          text-align: center;
        }
      `}</style>
    </div>
  );
}

export default DraftPlaceholderView;
