import useDialogState from './useDialogState';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-feather';
import SpaceTitleModal from './SpaceTitleModal';

function RenameSpaceButton({ space }) {
  // handle rename
  const [showRenameDialog, openRenameDialog, closeRenameDialog] =
    useDialogState(false);

  return (
    <>
      {showRenameDialog && (
        <SpaceTitleModal
          currentTitle={space.title}
          spaceId={space._id}
          closeModal={closeRenameDialog}
        />
      )}
      <Button
        variant="outline-primary"
        className="btn-icon"
        onClick={openRenameDialog}
      >
        <Icon.Edit3 />
      </Button>
    </>
  );
}

export default RenameSpaceButton;
