export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileClassnames(file) {
  let fileIconClassName = 'far fa-file-alt';
  let fileColorClassName = 'tx-gray-600';
  switch (file.fileType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      fileIconClassName = 'far fa-file-word';
      fileColorClassName = 'tx-primary';
      break;
    case 'application/pdf':
      fileIconClassName = 'far fa-file-pdf';
      fileColorClassName = 'tx-danger';
      break;
    case 'image/png':
    case 'image/jpeg':
      fileIconClassName = 'far fa-file-image';
      fileColorClassName = 'tx-indigo';
      break;
    case 'application/excel':
    case 'application/vnd.ms-excel':
    case 'application/x-excel':
    case 'application/x-msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      fileIconClassName = 'far fa-file-excel';
      fileColorClassName = 'tx-success';
      break;
    case 'application/mspowerpoint':
    case 'application/powerpoint':
    case 'application/vnd.ms-powerpoint':
    case 'application/x-mspowerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      fileIconClassName = 'far fa-file-powerpoint';
      fileColorClassName = 'tx-orange';
      break;
    case 'image/tiff':
      fileIconClassName = 'far fa-file-image';
      fileColorClassName = 'tx-indigo';
      break;
    case 'application/postscript':
      fileIconClassName = 'far fa-file-image';
      fileColorClassName = 'tx-orange';
      break;
    case 'application/zip':
      fileIconClassName = 'far fa-file-archive';
      fileColorClassName = 'tx-gray-700';
      break;
    case 'video/mp4':
      fileIconClassName = 'fas fa-video';
      fileColorClassName = 'tx-primary';
      break;

    default:
      fileIconClassName = 'far fa-file-alt';
      fileColorClassName = 'tx-gray-600';
      break;
  }

  return {
    fileIconClassName,
    fileColorClassName,
  };
}

export function getIntegrationClassnames(integrationType) {
  let iconClassName = '';
  let color = '';

  switch (integrationType) {
    case 'youtube':
      iconClassName = 'fab fa-youtube';
      color = '#c4302b';
      break;

    default:
      break;
  }

  return {
    iconClassName,
    color,
  };
}

export function detectTouchscreen() {
  var result = false;
  if (window.PointerEvent && 'maxTouchPoints' in navigator) {
    // if Pointer Events are supported, just check maxTouchPoints
    if (navigator.maxTouchPoints > 0) {
      result = true;
    }
  } else {
    // no Pointer Events...
    if (
      window.matchMedia &&
      window.matchMedia('(any-pointer:coarse)').matches
    ) {
      // check for any-pointer:coarse which mostly means touchscreen
      result = true;
    } else if (window.TouchEvent || 'ontouchstart' in window) {
      // last resort - check for exposed touch events API / event handler
      result = true;
    }
  }
  return result;
}
