import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from './useActions';

import * as Icon from 'react-feather';
import { LibraryViewSelector, LibraryViewAction } from '../state/libraryView';
import Link from 'next/link';
import TagTitleView from './TagTitleView';
import Dropdown from 'react-bootstrap/Dropdown';

function LibraryBreadcrumb(props) {
  const { file } = props;
  const viewMode = useSelector(LibraryViewSelector.getViewMode);
  const currentFolder = useSelector(LibraryViewSelector.getCurrentFolder);

  const goToFolder = useActions(LibraryViewAction.goToFolder);
  const goToFolderWithId = useActions(LibraryViewAction.goToFolderWithId);
  const goToPersonalFolder = useActions(LibraryViewAction.goToPersonalFolder);

  // handle open folder
  const handleOpenFolder = (folder) => (e) => {
    e.preventDefault();
    goToFolder(folder);
  };

  // handle open personal folder
  const handleOpenPersonalFolder = (folder) => (e) => {
    e.preventDefault();
    goToPersonalFolder(folder);
  };

  // tag
  const currentTag = useSelector(LibraryViewSelector.getCurrentTag);

  const handleViewModeChange = (e) => {
    if (e.target.value === 'mine') {
      goToPersonalFolder(null);
    } else {
      if (e.target.value === 'folder') {
        goToFolder(null);
      } else {
        goToFolderWithId(e.target.value);
      }
    }
  };

  const folders = useSelector(LibraryViewSelector.getTopLevelFolders) || [];

  return (
    <h6 className="mg-b-0">
      {viewMode === 'tag' && <TagTitleView tag={currentTag} />}
      {(viewMode === 'folder' || viewMode === 'mine') && (
        <>
          <select
            className="root-folder-select tx-primary"
            value={viewMode}
            onChange={handleViewModeChange}
          >
            <option value="mine">My Uploads</option>
            <option value="folder">Team Drive</option>
          </select>
          {currentFolder && (
            <span key={currentFolder._id} className="tx-gray-500">
              {' '}
              / {currentFolder.title}
            </span>
          )}
        </>
      )}

      <style jsx>{`
        .root-folder-select {
          border: none;
          outline: none;
          background: transparent;
          display: inline;
        }
      `}</style>
    </h6>
  );
}

export default LibraryBreadcrumb;
