import SigninForm from './SigninForm';
import { fetchPlaybooks } from '../api/backdrop';
import { SpaceViewAction } from '../state/spaceView';
import { useActions } from './useActions';

function SigninScreen() {
  const setPlaybooks = useActions(SpaceViewAction.setPlaybooks);
  const handleLoginSuccess = async () => {
    const playbooks = await fetchPlaybooks();
    setPlaybooks(playbooks);
  };

  return (
    <div className="content">
      <SigninForm onSuccess={handleLoginSuccess} />
    </div>
  );
}

export default SigninScreen;
