import React, { useState, useRef, useEffect } from 'react';
import api from '../api';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function RenameBackdropModal(props) {
  const { closeModal, backdrop } = props;

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  // const viewerUrl = useSelector(AuthUserSelector.getViewerUrl);
  // const url = `${viewerUrl}/${backdrop.code}`;

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setProcessing(true);
    setError('');

    const newTitle = inputRef.current.value;

    try {
      await api.service('spaces').patch(backdrop.space._id, {
        title: newTitle,
      });

      // await updateUrlInMailBody(backdrop.code, newTitle, url);

      // then close
      closeModal();
    } catch (err) {
      console.log('err', err);
      const message =
        err.code === 404
          ? 'You do not have permission to perform this action'
          : err.message;
      setError(message);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <Modal show onHide={closeModal}>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="folderName">
            <Form.Label>Enter a new title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Untitled"
              ref={inputRef}
              defaultValue={backdrop.space?.title}
              required
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={processing}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={processing}>
            {processing ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Update</span>
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RenameBackdropModal;
