import * as React from 'react';

import WelcomeScreen from './WelcomeScreen';
import SigninScreen from './SigninScreen';
import MainContent from './MainContent';
import { usePluginLoadedFirstTime } from './usePluginHost';
import { useSelector } from 'react-redux';
import { AuthUserSelector } from '../state/authUser';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function HomeScreen(props) {
  const authenticated = useSelector(AuthUserSelector.isAuthenticated);

  const [isFirstTime, markAsLoaded] = usePluginLoadedFirstTime();
  const showLogin = !isFirstTime && !authenticated;
  const showContent = !isFirstTime && authenticated;

  return (
    <DndProvider backend={HTML5Backend}>
      {isFirstTime && <WelcomeScreen onStart={markAsLoaded} />}
      {showLogin && <SigninScreen />}
      {showContent && <MainContent />}
    </DndProvider>
  );
}

export default HomeScreen;
