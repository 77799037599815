import React, { useState } from 'react';
import api from '../api';
import startsWith from 'lodash/startsWith';

import { useSelector } from 'react-redux';
import { SpaceViewAction, SpaceViewSelector } from '../state/spaceView';
import { useActions } from './useActions';
import { WizardAction } from '../state/wizard';
import { AuthUserSelector } from '../state/authUser';
import { NotificationAction } from '../state/notification';
import { usePluginHost } from './usePluginHost';
import { updateSpaceTitle } from '../api/space';
import { getStorate } from './universalStorage';

function StepSelectBackdrop(props) {
  const selectInvite = useActions(SpaceViewAction.selectInvite);
  const goToStepBackdrop = useActions(WizardAction.goToStepBackdrop);
  const goToStepDraftSpace = useActions(WizardAction.goToStepDraftSpace);
  const allItems = useSelector(SpaceViewSelector.getActiveBackdrops);

  const handleBackdropChange = (e) => {
    const selected = allItems.find(
      (backdrop) => backdrop.code === e.target.value
    );
    selectInvite(selected);
    goToStepBackdrop();
  };

  const currentMode = useSelector(SpaceViewSelector.getCurrentMode);
  const draftSpace = useSelector(SpaceViewSelector.getDraftSpace);
  const setDraftSpace = useActions(SpaceViewAction.setDraftSpace);
  const showNotification = useActions(NotificationAction.show);
  const user = useSelector(AuthUserSelector.getUser);

  const { getSubject } = usePluginHost();
  const handleCreateNewClick = async (e) => {
    e.preventDefault();

    let shouldSync = false;
    let emailSubject = '';

    if (currentMode === 'compose') {
      emailSubject = await getSubject();
      shouldSync =
        emailSubject && !startsWith(emailSubject.toLowerCase(), 're:');
    }

    if (draftSpace) {
      if (shouldSync) {
        await updateSpaceTitle(draftSpace._id, emailSubject);
      }
      goToStepDraftSpace();
    } else {
      const title = shouldSync ? emailSubject : 'Sales Package';
      try {
        const data = {
          title,
          posts: [],
        };
        if (user.defaultTemplateId) {
          data.draftTemplate = user.defaultTemplateId;
        }
        const space = await api.service('spaces').create(data);

        const storage = getStorate();
        storage.setItem('draftSpaceId', space._id);
        setDraftSpace(space);
        goToStepDraftSpace();
      } catch (ex) {
        showNotification({
          title: 'Error',
          body: 'An error occurred while creating the backdrop. Please try again.',
        });
      }
    }
  };

  return (
    <div className="pd-20 mg-t-10">
      <h3 className="mg-b-20 tx-light wd-200">Select a backdrop</h3>
      <p>
        Select an existing backdrop you've sent to <i>{props.recipient}</i>{' '}
        or&nbsp;
        <a href="#" onClick={handleCreateNewClick}>
          create a new one
        </a>
      </p>

      <select className="custom-select" onChange={handleBackdropChange}>
        <option value="-">Select a backdrop</option>
        {allItems.map((item) => (
          <option key={item._id} value={item.code}>
            {item.space?.title}
          </option>
        ))}
      </select>
    </div>
  );
}

export default StepSelectBackdrop;
