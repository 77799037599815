import Button from 'react-bootstrap/Button';

function WelcomeScreen({ onStart }) {
  return (
    <div className="content">
      <div className="welcome-screen tx-center animate__animated animate__fadeIn">
        <header className="mg-t-40 mg-b-50">
          <h1 className="tx-light tx-40">Welcome</h1>
        </header>
        <main>
          <h2 className="tx-light tx-20 mg-b-20">
            Get tactical customer insights delivered daily
          </h2>
          <ul className="tx-left tx-14 mg-b-40">
            <li className="mg-b-5">
              <span className="ms-font-m">
                Real time signals as your customers engage
              </span>
            </li>
            <li className="mg-b-5">
              <span className="ms-font-m">
                Daily reports on customer insights
              </span>
            </li>
            <li className="mg-b-5">
              <span className="ms-font-m">
                Track your customers engagements
              </span>
            </li>
          </ul>
          <p className="tx-left tx-12">
            Note: You must have a Backdrop.io account to use this plugin. If you
            do not, you can start a{' '}
            <a
              className="ms-Link"
              href="https://app.backdrop.io/demo2"
              target="_blank"
            >
              Trial
            </a>
            .
          </p>

          <div className="mg-t-50">
            <Button
              className="mg-t-40"
              variant="brand-02"
              block
              onClick={onStart}
            >
              Get Started
            </Button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default WelcomeScreen;
