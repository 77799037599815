const EventEmitter = require('eventemitter3');
const hostEE = new EventEmitter();

if (process.browser) {
  window.addEventListener('message', function (event) {
    if (event.origin.match(/^https:\/\/\w+\.google\.com$/)) {
      if (typeof event.data === 'object') {
        const { type } = event.data;
        hostEE.emit(type, event.data);

        if (type === 'sdkEvent') {
          hostEE.emit(event.data.eventName, event.data.event);
        } else if (type === 'methodCallResponse') {
          console.log('methodCallResponse', event.data);
          const { id } = event.data;
          hostEE.emit(`methodCallResponse-${id}`, event.data);
        }
      }
    } else {
      // ignore
    }
  });
}

const COMMAND_TIMEOUT = 1000; // in ms

function sendCommand(namespace, method = '', args = []) {
  const id = Math.random().toString(36).substring(7);
  const payload = {
    type: 'methodCall',
    id,
    namespace,
    method,
    args,
  };
  window.top.postMessage(payload, '*');

  return id;
}

export function getToContacts() {
  console.log('gmail::getToRecipients()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Compose', 'getToRecipients');
    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      console.log('methodCallResponse', resp);
      const emailAddressDetails = resp.response;
      if (emailAddressDetails.length > 0) {
        resolve(emailAddressDetails.map((entry) => entry.emailAddress));
      } else {
        resolve([]);
      }
    });
  });
}

export function getCcContacts() {
  console.log('gmail::getCollaborators()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Compose', 'getCcRecipients');
    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      console.log('methodCallResponse', resp);
      const emailAddressDetails = resp.response;
      if (emailAddressDetails.length > 0) {
        resolve(emailAddressDetails.map((entry) => entry.emailAddress));
      } else {
        resolve([]);
      }
    });
  });
}

// subject
export function getSubject() {
  console.log('gmail::getSubject()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Compose', 'getSubject');
    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      console.log('methodCallResponse', resp);
      const subject = resp.response;
      if (subject && subject.length > 0) {
        resolve(subject);
      } else {
        resolve('');
      }
    });
  });
}

export async function registerRecipientsChangeHandlerAsync(callback) {
  hostEE.on('Compose.recipientsChanged', callback);
}

export async function deregisterRecipientsChangeHandlerAsync(callback) {
  hostEE.off('Compose.recipientsChanged', callback);
}

export function getActiveBackdropCode() {
  console.log('gmail::getActiveBackdropCode()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Backdrop', 'getActiveBackdropCode');
    let resolved = false;

    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      resolve(resp.response);
      resolved = true;
    });

    // timed out, probably client on old version
    setTimeout(() => {
      if (!resolved) {
        resolve('');
      }
    }, COMMAND_TIMEOUT);
  });
}

export function isInAnEmailThread() {
  console.log('gmail::isInAnEmailThread()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Compose', 'isReply');
    let resolved = false;

    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      resolve(resp.response);
      resolved = true;
    });

    // timed out, probably client on old version
    setTimeout(() => {
      if (!resolved) {
        resolve(false);
      }
    }, COMMAND_TIMEOUT);
  });
}

export function closeDrawer() {
  console.log('gmail::closeDrawer()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Backdrop', 'closeDrawer');
    let resolved = false;

    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      resolve(resp.response);
      resolved = true;
    });

    // timed out, probably client on old version
    setTimeout(() => {
      if (!resolved) {
        resolve(false);
      }
    }, COMMAND_TIMEOUT);
  });
}

export function getVersion() {
  console.log('gmail::getVersion()');
  return new Promise(function (resolve) {
    const sentId = sendCommand('Backdrop', 'getVersion');
    let resolved = false;

    hostEE.once(`methodCallResponse-${sentId}`, (resp) => {
      resolve(resp.response);
      resolved = true;
    });

    // timed out, probably client on old version
    setTimeout(() => {
      if (!resolved) {
        resolve('1.0.0');
      }
    }, COMMAND_TIMEOUT);
  });
}

// settings
export function resetSettings() {
  localStorage.removeItem('isFirstLoaded');
}

// mail function
export function insertUrlInMailBody(code, title, url) {
  sendCommand('Compose', 'insertLinkIntoBodyAtCursor', [title, url]);
}
