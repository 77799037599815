import React, { useState } from 'react';
import api from '../api';
import { useSelector } from 'react-redux';
import { LibraryViewAction, LibraryViewSelector } from '../state/libraryView';
import { NotificationAction } from '../state/notification';

import * as Icon from 'react-feather';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { useActions } from './useActions';

function getNumDoc(num) {
  if (num === 1) {
    return `${num} document`;
  }

  return `${num} documents`;
}

function LibraryFooter({ spaceId }) {
  const [processing, setProcessing] = useState(false);
  const selectedItems = useSelector(LibraryViewSelector.getSelectedMedia);
  const hasSelectedItems = selectedItems.length > 0;

  const closeLibrary = useActions(LibraryViewAction.close);

  const showNotification = useActions(NotificationAction.show);

  const handleAdd = async () => {
    if (!spaceId) {
      showNotification({
        title: 'Error',
        body: 'Fatal error: no spaces found.',
      });
      return;
    }

    if (!hasSelectedItems) {
      showNotification({
        title: 'Error',
        body: 'Please add some files to the backdrop.',
      });
      return;
    }

    setProcessing(true);
    try {
      // 1. refetch the space
      const space = await api.service('spaces').get(spaceId);
      const postIds = space.posts.map((p) => p._id);

      // added posts
      const newPosts = await Promise.all(
        selectedItems.map((file) =>
          api.service('posts').create({
            media: [file._id],
          })
        )
      );
      const newPostIds = newPosts.map((p) => p._id);

      // merged posts
      const nextPostIds = [...postIds, ...newPostIds];

      await api.service('spaces').patch(spaceId, {
        posts: nextPostIds,
      });

      setProcessing(false);

      closeLibrary();
    } catch (error) {
      showNotification({
        title: 'Error',
        body: error.message,
      });
      setProcessing(false);
    }
  };

  return (
    <div className="app-sticky-footer bg-gray-200">
      <div className="d-flex flex-row justify-content-between pd-r-15 pd-b-20">
        <Button
          className="mg-t-20 tx-gray-900"
          variant="link"
          disabled={processing}
          onClick={closeLibrary}
        >
          <Icon.ArrowLeft size={16} /> Go back
        </Button>
        <Button
          className="mg-t-20"
          variant="brand-02"
          disabled={processing || !hasSelectedItems}
          onClick={handleAdd}
        >
          {processing ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>
              {hasSelectedItems
                ? `Add ${getNumDoc(selectedItems.length)}`
                : 'Add to backdrop'}
            </span>
          )}
        </Button>
      </div>

      <style jsx>{`
        .app-sticky-footer {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
        }
      `}</style>
    </div>
  );
}

export default LibraryFooter;
