import api from './';

export async function findSpacePermissions(spaceId) {
  return api.service(`spaces/${spaceId}/custom_permissions`).find({});
}

export async function getSpacePermission(spaceId, permissionId) {
  return api.service(`spaces/${spaceId}/custom_permissions`).get(permissionId);
}

export async function createSpacePermission(spaceId, data) {
  return api.service(`spaces/${spaceId}/custom_permissions`).create(data);
}

export async function removeSpacePermission(spaceId, permissionId) {
  return api
    .service(`spaces/${spaceId}/custom_permissions`)
    .remove(permissionId);
}

export async function updateSpaceNotificationPreference(
  spaceId,
  permissionId,
  notificationPreference
) {
  return api
    .service(`spaces/${spaceId}/custom_permissions`)
    .patch(permissionId, { notificationPreference });
}

export async function annotatePost(spaceId, postId, annotation) {
  return api.service(`spaces/${spaceId}/posts`).patch(postId, {
    annotation,
  });
}

export function archiveSpace(spaceId) {
  return api.service('spaces').patch(spaceId, { status: 'archived' });
}

export async function pinPost(spaceId, postId) {
  return api.service('spaces').patch(spaceId, {
    pinnedPostId: postId,
  });
}

export async function unpinPost(spaceId, postId) {
  return api.service('spaces').patch(spaceId, {
    pinnedPostId: null,
  });
}

export async function updateSpaceTemplate(spaceId, templateId) {
  return api.service('spaces').patch(spaceId, {
    draftTemplate: templateId,
  });
}

export async function updateSpaceTitle(spaceId, title) {
  return api.service('spaces').patch(spaceId, {
    title,
  });
}
