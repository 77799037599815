import React from 'react';

import * as Icon from 'react-feather';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import BackdropContent from './BackdropContent';
import PostContextMenu from './PostContextMenu';
import RenameBackdropButton from './RenameBackdropButton';

import { useSelector } from 'react-redux';
import { SpaceViewAction, SpaceViewSelector } from '../state/spaceView';
import { useActions } from './useActions';
import DocumentContextMenu from './DocumentContextMenu';

function StepBackdrop() {
  const selectInvite = useActions(SpaceViewAction.selectInvite);

  const allItems = useSelector(SpaceViewSelector.getActiveBackdrops);
  const backdrop = useSelector(SpaceViewSelector.getSelectedBackdrop);
  const hasBackdrop = backdrop !== null;

  const handleBackdropChange = (e) => {
    const selected = allItems.find(
      (backdrop) => backdrop.code === e.target.value
    );
    selectInvite(selected);
  };

  return (
    <div>
      <PostContextMenu />
      <DocumentContextMenu />

      <div>
        <div className="pd-15">
          <InputGroup>
            <select
              className="custom-select"
              value={backdrop?.code}
              onChange={handleBackdropChange}
            >
              {allItems.map((item) => (
                <option key={item._id} value={item.code}>
                  {item.space?.title}
                </option>
              ))}
            </select>
            <InputGroup.Append>
              <RenameBackdropButton backdrop={backdrop} />
            </InputGroup.Append>
          </InputGroup>
        </div>

        {hasBackdrop && <BackdropContent />}
      </div>
    </div>
  );
}

export default StepBackdrop;
