import { useState, useEffect } from 'react';
import api from '../api';
import Uppy from '@uppy/core';
import S3 from '@uppy/aws-s3';
import { isEmpty, endsWith } from 'lodash/fp';
import useUploadConfig, { defaultUploadConfig } from './useUploadConfig';
// import { removeAttachmentAsync } from '../api/outlook';

function setup(
  { spaceId, inviteCode, closeModal },
  uploadConfig = defaultUploadConfig
) {
  if (!spaceId) {
    throw new Error('Invalid backdrop');
  }

  const uppy = Uppy({
    autoProceed: true,
    allowMultipleUploads: true,
    restrictions: {
      maxFileSize: uploadConfig.maxFileSize,
      maxNumberOfFiles: 100,
      allowedFileTypes: uploadConfig.allowedFileTypes,
    },
    locale: {
      strings: {
        youCanOnlyUploadX: {
          0: 'You can only upload %{smart_count} file',
          1: 'You can only upload %{smart_count} files',
        },
        youHaveToAtLeastSelectX: {
          0: 'You have to select at least %{smart_count} file',
          1: 'You have to select at least %{smart_count} files',
        },
        // **NOTE**: This string is called `exceedsSize2` for backwards compatibility reasons.
        // See https://github.com/transloadit/uppy/pull/2077
        exceedsSize2: 'This file exceeds maximum allowed size of %{size}',
        youCanOnlyUploadFileTypes:
          'You can only upload: PDF, Office Document (docx, pptx, xlsx), Postscript (ai, eps, ps) or Image (jpg, jpeg, png, tiff)',
        companionError: 'Connection with Companion failed',
      },
    },
  });

  uppy.use(S3, {
    limit: 10,
    async getUploadParameters(file) {
      let fileType = file.type;

      if (fileType === '' && endsWith('.xls', file.name)) {
        fileType = 'application/vnd.ms-excel';
      }

      const payload = {
        title: file.name,
        type: 'file',
        fileName: file.name,
        fileType,
        fileSize: file.size,
        private: true, // file will be under "My Uploads"
      };

      const media = await api.service('media').create(payload);
      uppy.setFileMeta(file.id, {
        mediaId: media._id,
        spaceId,
        inviteCode,
      });

      return {
        method: 'PUT',
        url: media.uploadUrl,
        fields: [],
        // Provide content type header required by S3
        headers: {
          'Content-Type': fileType,
        },
      };
    },
  });

  uppy.on('complete', async (result) => {
    const files = result.successful || [];
    if (!files.length) {
      return;
    }

    const firstFile = files[0];
    const { spaceId, inviteCode } = firstFile.meta;

    if (spaceId) {
      const tasks = files.map((file) =>
        api.service('posts').create({
          media: [file.meta?.mediaId],
        })
      );

      const posts = await Promise.all(tasks);

      // update backdrop
      const space = await api.service('spaces').get(spaceId);
      const oldPostIds = space.posts.map((p) => p._id);
      const newPostIds = posts.map((p) => p._id);

      const nextPostIds = [...oldPostIds, ...newPostIds];

      await api.service('spaces').patch(spaceId, {
        posts: nextPostIds,
      });

      // optionally notify
      if (inviteCode) {
        await api.service('space-invites').patch(inviteCode, {
          notify_changes: true,
          num_items_added: newPostIds.length,
        });
      }
    }

    // close
    closeModal && closeModal();
  });

  uppy.on('restriction-failed', (file, error) => {
    // do some customized logic like showing system notice to users
    console.log('restriction-failed', file, error);
  });

  uppy.on('upload-success', async (file, response) => {
    const { mediaId, spaceId, outlookAttachment } = file.meta;
    if (mediaId && spaceId) {
      try {
        // mark as uploaded
        const media = await api.service('media').patch(mediaId, {
          uploadPending: false,
        });

        // generate thumbnail
        if (defaultUploadConfig.renderableFileTypes.includes(file.type)) {
          await api.service(`media/${mediaId}/thumbnail`).create({});
        }
      } catch (ex) {
        // ignore
        console.log('failed to finish upload');
        console.error(ex);
      }
    }

    // if (outlookAttachment) {
    //   try {
    //     await removeAttachmentAsync(outlookAttachment.id);
    //   } catch (ex) {
    //     // ignore
    //     console.log('failed to finish upload');
    //     console.error(ex);
    //   }
    // }
  });

  uppy.on('upload-error', async (file, error, response) => {
    const { mediaId } = file.meta;
    console.log('upload-error', file, error, response);
    if (mediaId) {
      try {
        // delete media object
        await api.service('media').remove(mediaId);
      } catch (error) {
        // ignore
      }
    }
  });

  // uppy.on('file-removed', async (file) => {
  //   const { mediaId } = file.meta;
  //   console.log('file-removed', file);
  //   if (mediaId) {
  //     try {
  //       // delete media object
  //       await api.service('media').remove(mediaId);
  //     } catch (error) {
  //       // ignore
  //       console.log('failed to delete media object', error);
  //     }
  //   }
  // });

  return uppy;
}

export default function useBackdropUppy({
  spaceId,
  inviteCode = null,
  closeModal,
}) {
  const [uppy, setUppy] = useState(null);
  const [uploadConfig] = useUploadConfig();

  useEffect(() => {
    const uppy = setup({ spaceId, inviteCode, closeModal }, uploadConfig);
    setUppy(uppy);

    return () => {
      uppy.close();
    };
  }, [spaceId, inviteCode]);

  return uppy;
}
