import api from '../api';
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import includes from 'lodash/includes';
import get from 'lodash/get';

function RemoveFromSpaceModal(props) {
  const { closeModal, spaceId, posts = [] } = props;
  const multiple = posts.length > 1;

  const firstMediaTitle = get(posts, [0, 'media', 0, 'title']);
  const contentText = multiple ? (
    <span className="tx-14 tx-bold">
      Are you sure you want to remove the {posts.length} selected documents from
      current space?
    </span>
  ) : (
    <span className="tx-14 tx-bold">
      Are you sure you want to remove "{firstMediaTitle}" from current space?
    </span>
  );

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const handleConfirm = async () => {
    setProcessing(true);

    const removingIds = posts.map((p) => p._id);
    try {
      const space = await api.service('spaces').get(spaceId);
      const oldPosts = space.posts || [];
      const nextPosts = oldPosts.filter((p) => !includes(removingIds, p._id));
      const nextPostIds = nextPosts.map((p) => p._id);
      await api.service('spaces').patch(spaceId, {
        posts: nextPostIds,
      });
      closeModal();
    } catch (error) {
      console.log('cannot move to trash');
      console.error(error);
      setError(error.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton={false}>
        <Modal.Title as="h6">Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {contentText} <br />
        <span>This action cannot be undone.</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} disabled={processing}>
          Cancel
        </Button>
        <Button variant="danger" disabled={processing} onClick={handleConfirm}>
          {processing ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Remove</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemoveFromSpaceModal;
